import {getPlatformName, getSocialProfileIcon} from "@/utils/functions";
import VueMultiselect from "@/components/vue-multiselect";
import Checkbox from "@/components/checkbox";
import SubjectPreview from "@/containers/subject-preview";

import axios from "@/axios";
import {cloneDeep} from "lodash";
export default {
    name: "intelligence",
    components: {
        VueMultiselect,
        checkbox: Checkbox,
        "neo-subject-preview": SubjectPreview,
    },
    props: {
        tabs: {
            type: Array,
            default: [],
        },
        options: {
            type: Object,
            default: {},
        },
        currentTab: String,
    },
    data() {
        return {
            selectedEntities: {},
            allEntities: [],
            filterOptions: {
                status: [
                    {
                        label: "Confirmed",
                        value: "confirmed",
                    },
                    {
                        label: "Potential",
                        value: "potential",
                    },
                ],
                identifiersCount: [
                    {
                        group: "Identifiers Count",
                        values: [
                            {
                                label: "1",
                                value: 1,
                            },
                            {
                                label: "2",
                                value: 2,
                            },
                            {
                                label: "3",
                                value: 3,
                            },
                            {
                                label: "4",
                                value: 4,
                            },
                            {
                                label: "5",
                                value: 5,
                            },
                            {
                                label: "6+",
                                value: 6,
                            },
                        ],
                    },
                ],
            },
            filters: {
                status: [
                    {
                        label: "Confirmed",
                        value: "confirmed",
                    },
                    {
                        label: "Potential",
                        value: "potential",
                    },
                ],
                identifiersCount: [],
            },
            loading: false,
            intelligenceData: null,
        };
    },
    computed: {
        getFilterPayload() {
            return {
                status: this.filters.status?.length
                    ? this.filters.status?.map((el) => {
                          return el.value.toUpperCase();
                      })
                    : ["CONFIRMED", "POTENTIAL"],
                identifiers: this.filters.identifiersCount.map((el) => el.value),
            };
        },
        totalSelectedEntities() {
            let total = 0;
            for (const tab of Object.keys(this.selectedEntities)) total += this.selectedEntities[tab]?.length;
            return total;
        },
        intelligenceTabs() {
            let tabs = cloneDeep(this.tabs);
            const location = this.tabs.find((tab) => tab.key === "address");
            if (location) {
                location.key = "location";
                tabs.push(location);
            }
            return tabs;
        },
    },
    async mounted() {},
    methods: {
        getPlatformName,
        getSocialProfileIcon,
        async beforeOpen() {
            this.resetData();
            this.loading = true;
            this.intelligenceData = await this.getIntelligenceData();
            for (const tab of this.tabs) {
                this.selectedEntities = {
                    ...this.selectedEntities,
                    [tab.key]: this.intelligenceData?.[tab.key]?.filter((el) => el.status === "CONFIRMED"),
                };
            }
            this.loading = false;
        },
        hideModal() {
            this.$modal.hide("run-intelligence");
        },
        getEntityStatus(status) {
            let data = {
                label: "Unknown",
                color: "gray",
                icon: "minus",
            };
            switch (status?.toLowerCase()) {
                case "confirmed":
                    data = {
                        label: "Confirmed",
                        color: "green",
                        icon: "check_solid",
                    };
                    break;
                case "potential":
                    data = {
                        label: "Potential",
                        color: "red",
                        icon: "question_solid",
                    };
                    break;
            }
            return data;
        },
        toggleEntity(entity, tab) {
            if (!this.selectedEntities) this.selectedEntities = {};
            if (!this.selectedEntities?.[tab.key])
                this.selectedEntities = {
                    ...this.selectedEntities,
                    [tab.key]: [],
                };

            let matchIndex = this.selectedEntities?.[tab.key].findIndex((el) => el.entity_id === entity.entity_id);
            if (matchIndex > -1) {
                this.selectedEntities[tab.key]?.splice(matchIndex, 1);
            } else {
                this.selectedEntities[tab?.key].push(entity);
            }
        },
        async getIntelligenceData() {
            // const { data } = await axios.get(`social-media-automation/run-intelligence-cards/${this.$store.getters.getCaseId}`);
            const payload = {
                case_id: this.$store.getters.getCaseId,
                ...this.getFilterPayload,
            };
            const {data} = await axios.post(`social-media-automation/run-intelligence-cards`, payload);
            if (data?.status) return data?.data;
            else return null;
        },
        runIntelligence() {
            this.$emit("runIntelligence", this.selectedEntities);
        },
        async applyFilters() {
            this.intelligenceData = await this.getIntelligenceData();
            this.selectedEntities = {};
            for (const tab of this.tabs) {
                this.selectedEntities = {
                    ...this.selectedEntities,
                    [tab.key]: this.intelligenceData?.[tab.key]?.filter((el) => el.status === "CONFIRMED"),
                };
            }
        },

        resetData() {
            this.selectedEntities = {};
            this.filters = {
                status: [
                    {
                        label: "Confirmed",
                        value: "confirmed",
                    },
                    {
                        label: "Potential",
                        value: "potential",
                    },
                ],
                identifiersCount: [],
            };
        },
    },
};
