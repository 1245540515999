import { render, staticRenderFns } from "./automation.html?vue&type=template&id=d95a6676&scoped=true&"
import script from "./automation.js?vue&type=script&lang=js&"
export * from "./automation.js?vue&type=script&lang=js&"
import style0 from "./automation.scss?vue&type=style&index=0&id=d95a6676&prod&scoped=true&lang=scss&"
import style1 from "./index.vue?vue&type=style&index=1&id=d95a6676&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d95a6676",
  null
  
)

export default component.exports